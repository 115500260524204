import { LiquidButton } from 'components/LiquidButton';
import { ImagesService } from 'service/images/imagesService';

export const LiquidButtonElement = ({
  text = '',
  width,
  ctaColor = '#EFDBB2',
  textColor = '#000',
  hasTheme,
}: {
  text: string;
  width: number;
  ctaColor?: string;
  textColor?: string;
  hasTheme?: boolean;
}) => (
  <LiquidButton
    text={text}
    height={50}
    width={width}
    color={ctaColor}
    textColor={textColor}
    useCustomColorsAsImportant={hasTheme}
  />
);

export const FullBleedSVG = ({ backgroundAsset }: { backgroundAsset: string }) => (
  <svg width="100%" viewBox="0 0 1440 600" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <clipPath id="banner2-mask3">
        <path
          d="M1440 0C1440 0 1115 46.8843 723 46.8843C331 46.8843 2.94946e-06 0.000123923 2.94946e-06 0.000123923L0 600C0 600 331 552.764 723 552.764C1115 552.764 1440 600 1440 600V0Z"
          fill="#FA85BD"
        />
      </clipPath>
    </defs>
    <image
      height="600"
      width="1440"
      preserveAspectRatio="xMidYMid slice"
      xlinkHref={ImagesService.getImageUrlWithCompressionParams(
        backgroundAsset ?? '',
        70,
        1440,
        undefined,
        false,
      )}
      clipPath="url(#banner2-mask3)"
    />
  </svg>
);

export const FullBleedMobileSVG = ({
  backgroundAsset,
  mobileBackgroundAsset,
}: {
  backgroundAsset: string;
  mobileBackgroundAsset: string;
}) => (
  <svg
    viewBox="0 0 276 460"
    preserveAspectRatio="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath id="banner2-mask4" clipPathUnits="objectBoundingBox">
        <path
          d="M0,0 L0,0.945 C0,0.945,0.226,1,0.498,1 C0.77,1,1,0.945,1,0.945 L1,0 C1,0,0.77,0.055,0.498,0.055 C0.226,0.055,0,0,0,0"
          fill="#FA85BD"
        />{' '}
      </clipPath>
    </defs>
    <image
      height="460"
      width="276"
      preserveAspectRatio="xMidYMid slice"
      xlinkHref={ImagesService.getImageUrlWithCompressionParams(
        (mobileBackgroundAsset || backgroundAsset) ?? '',
        70,
        276,
        undefined,
        true,
      )}
      clipPath="url(#banner2-mask4)"
    />
  </svg>
);

export const LeftSVG = ({ backgroundAsset }: { backgroundAsset: string }) => (
  <svg viewBox="0 0 710 600" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <defs>
      <clipPath id="banner2-mask-left">
        <path
          opacity="0.8"
          d="M0 600L2.62268e-05 0C2.62268e-05 0 325 51.7605 717 51.7605C718 51.7605 719 51.7602 720 51.7595V547.978C329.313 548.241 1.14596e-08 599.738 1.14596e-08 599.738L0 600Z"
          fill="#FA85BD"
        />
      </clipPath>
    </defs>
    <image
      height="600"
      width="710"
      preserveAspectRatio="xMidYMid slice"
      clipPath="url(#banner2-mask-left)"
      xlinkHref={ImagesService.getImageUrlWithCompressionParams(
        backgroundAsset ?? '',
        70,
        710,
        undefined,
        true,
      )}
    />
  </svg>
);

export const RightSVG = ({ backgroundAsset }: { backgroundAsset: string }) => (
  <svg viewBox="0 0 710 600" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <defs>
      <clipPath id="banner2-mask-right">
        <path
          opacity="0.8"
          d="M710 600L710 -3.14722e-05C710 -3.14722e-05 389.514 51.7605 2.9583 51.7605C1.97182 51.7605 0.985713 51.7602 2.36109e-05 51.7595L2.36202e-05 547.978C385.261 548.241 710 599.738 710 599.738L710 600Z"
          fill="#FA85BD"
        />
      </clipPath>
    </defs>
    <image
      height="600"
      width="710"
      preserveAspectRatio="xMidYMid slice"
      xlinkHref={ImagesService.getImageUrlWithCompressionParams(
        backgroundAsset ?? '',
        70,
        710,
        undefined,
        true,
      )}
      clipPath="url(#banner2-mask-right)"
    />
  </svg>
);

export const LeftRightMobileSVG = ({
  backgroundAsset,
  mobileBackgroundAsset,
}: {
  backgroundAsset: string;
  mobileBackgroundAsset: string;
}) => (
  <svg viewBox="0 0 320 300" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <defs>
      <clipPath id="banner2-mask2">
        <path
          d="M0 0L3.51906e-05 283.392C3.51906e-05 283.392 72.2221 299.801 159.333 299.801C246.444 299.801 320 283.392 320 283.392L320 3.06226e-05C320 3.06226e-05 246.444 16.4091 159.333 16.4091C72.252 16.4091 0.0494087 0.0112235 0 0Z"
          fill="#FA85BD"
        />
      </clipPath>
    </defs>
    <image
      height="300"
      width="320"
      preserveAspectRatio="xMidYMid slice"
      xlinkHref={ImagesService.getImageUrlWithCompressionParams(
        (mobileBackgroundAsset || backgroundAsset) ?? '',
        70,
        320,
        undefined,
        true,
      )}
      clipPath="url(#banner2-mask2)"
    />
  </svg>
);

import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import { Title, Text as Paragraph } from '../../components/Typography';

interface IBanner2ContentProps {
  $textAlignment?: string;
  fullBleed?: true | false;
}

export const VerticalAlignMiddle = tw.div`
flex
h-full
items-center
`;
VerticalAlignMiddle.displayName = 'VerticalAlignMiddle';

export const FullBleedImageTextContainer = styled.div`
  display: none;
  flex-direction: row;
  padding-top: 10%;
  padding-bottom: 10%;
  overflow: hidden;

  @media (min-width: 768px) {
    display: block;
    position: relative;
  }
`;
FullBleedImageTextContainer.displayName = 'FullBleedImageTextContainer';

export const FullBleedImageTextWrapper = styled.div<IBanner2ContentProps>`
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  @media (min-width: 768px) {
    position: relative;
  }
  @media (max-width: 768px) {
    position: relative;
    height: 380px;
  }

  ${(p: IBanner2ContentProps) =>
    p.$textAlignment === 'Right'
      ? `
      justify-content: right;
    `
      : `
      justify-content: left;
    `}
`;
FullBleedImageTextWrapper.displayName = 'FullBleedImageTextWrapper';

export const FullBleedImage = styled.div`
  width: 100%;
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 10px;
`;
FullBleedImage.displayName = 'FullBleedImage';

export const FullBleedText = styled.div`
  position: absolute;
  z-index: 1;
  text-align: center;
  padding: 5%;
  width: 100%;
  @media (min-width: 768px) {
    text-align: left;
    width: 50%;
  }

  @media (min-width: 768px) and (max-width: 828px) {
    top: 10%;
  }
  @media (min-width: 829px) and (max-width: 1023px) {
    top: 15%;
  }

  @media (min-width: 1024px) and (max-width: 1054px) {
    top: 6%;
    width: 50%;
  }

  @media (min-width: 1053px) and (max-width: 1200px) {
    top: 10%;
    width: 50%;
  }
  @media (max-width: 767px) {
    bottom: 0;
  }
`;
FullBleedText.displayName = 'FullBleedText';

export const CurvesBothSides = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;

  & > * {
    min-height: inherit;
  }

  @media (min-width: 1024px) {
    min-height: 600px;
  }
`;

export const ImageTextWrapper = styled.div<IBanner2ContentProps>`
  padding-top: 5%;
  display:none;
  @media (min-width: 768px) {
    display: block;
  }

    ${(p: IBanner2ContentProps) =>
      p.$textAlignment === 'Left' ? null : 'flex-direction: row-reverse;'}

    svg {
      min-width: fit-content;
    }
  }
`;
ImageTextWrapper.displayName = 'ImageTextWrapper';

export const RightImageContainer = styled.div`
  flex: 1;
  margin: auto;
  margin-right: -15%;
  height: 100%;
  @media (min-width: 1600px) {
    margin-right: -25%;
  }
  @media (min-width: 1920px) {
    margin-right: -32%;
  }
`;
RightImageContainer.displayName = 'RightImageContainer';

export const LeftImageContainer = styled.div`
  flex: 1;
  margin: auto;
  margin-left: -15%;
  height: 100%;
  @media (min-width: 1600px) {
    margin-left: -25%;
  }
  @media (min-width: 1920px) {
    margin-left: -35%;
  }

  & > div {
    height: 100%;
  }
`;
LeftImageContainer.displayName = 'LeftImageContainer';

export const LeftRightTextContainer = styled.div`
  padding-top: 10%;
  align-content: center;
  flex: 1;
  margin: auto;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  @media (min-width: 768px) {
    text-align: left;
    padding-left: 5%;
    padding-right: 5%;
  }
  @media (min-width: 768px) and (max-width: 799px) {
    margin-top: 10%;
  }
  @media (min-width: 800px) and (max-width: 900px) {
    margin-top: 15%;
  }
  @media (min-width: 901px) and (max-width: 1023px) {
    margin-top: 20%;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-top: 0%;
  }
  @media (max-width: 767px) {
    padding-top: 30px;
  }
`;
LeftRightTextContainer.displayName = 'LeftRightTextContainer';

export const SuperText: any = tw(Paragraph as any)`
  mb-2
  text-darkgray
  text-tertiary
  opacity-70
  pt-0.5
  text-center
  md:text-left
`;
SuperText.displayName = 'SuperText';

export const ProductTitle: any = tw(Title as any)`
  mb-2.5
  text-left
  md:text-left
  sm:text-center
  text-2xl
  lg:text-6xl
  text-primary
`;
ProductTitle.displayName = 'ProductTitle';

export const Description: any = tw(Paragraph as any)`
  text-tertiary
`;
Description.displayName = 'Description';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
ButtonContainer.displayName = 'ButtonContainer';

export const ButtonStyled = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;
ButtonStyled.displayName = 'ButtonStyled';

export const ButtonMobileStyled = styled.div`
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
`;
ButtonMobileStyled.displayName = 'ButtonMobileStyled';

export const ImageTextWrapperMobile = styled.div`
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
`;
ImageTextWrapperMobile.displayName = 'ImageTextWrapperMobile';

export const LeftRightImageContainerMobile = styled.div`
   {
    flex: 1;
    margin: auto;
  }
`;
LeftRightImageContainerMobile.displayName = 'LeftRightImageContainerMobile';

export const FullBleedWrapperMobile = styled.div`
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
`;
FullBleedWrapperMobile.displayName = 'FullBleedWrapperMobile';

export const FullBleedImageTextContainerMobile = styled.div`
  display: block;
  text-align: center;
  @media (min-width: 768px) {
    display: none;
    text-align: left;
  }
  image {
    overflow-y: hidden;
  }
`;
FullBleedImageTextContainerMobile.displayName = 'FullBleedImageTextContainerMobile';

import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import NextImage from 'components/NextImage';
import { NextLink } from 'components/NextLink';
import { ReactNode } from 'react';
import type { Banner2 as TBanner2 } from 'server/repository/hygraph/generated/graphqlTypes';
import { LocalizationService } from 'service/localization/localizationService';
import { COLORS } from 'styles/constants';
import type { ISharedTemplateProps, UseExtendedImage } from 'templates/TemplateMapper/types';
import FadeInSection from '../../components/FadeInSection';
import { Grid, GridItem, GridRow } from '../../components/Grid';
import { FullBleedMobileSVG, FullBleedSVG, LiquidButtonElement } from './components';
import {
  ButtonContainer,
  ButtonMobileStyled,
  ButtonStyled,
  CurvesBothSides,
  Description,
  FullBleedImage,
  FullBleedImageTextContainer,
  FullBleedImageTextContainerMobile,
  FullBleedImageTextWrapper,
  FullBleedText,
  ImageTextWrapper,
  ImageTextWrapperMobile,
  LeftImageContainer,
  LeftRightImageContainerMobile,
  LeftRightTextContainer,
  ProductTitle,
  RightImageContainer,
  SuperText,
  VerticalAlignMiddle,
} from './styles';

export type Banner2TemplateProps = UseExtendedImage<
  TBanner2,
  ['backgroundAsset', 'mobileBackgroundAsset']
>;

export interface IBanner2Props extends ISharedTemplateProps {
  templateProps: Banner2TemplateProps;
  children?: ReactNode | ReactNode[];
}

export const Banner2 = (
  {
    templateProps: {
      textAlignment,
      fullBleed = false,
      superText = '',
      title,
      link,
      description,
      buttonColor,
      backgroundAsset,
      mobileBackgroundAsset,
    },
    primarySubBrandColor,
    theme,
  }: IBanner2Props,
  { id = 'banner2' },
) => {
  const { pathTranslations } = useGlobalContext();
  const btnHex = primarySubBrandColor
    ? primarySubBrandColor
    : buttonColor === 'Light'
      ? COLORS.secondary
      : COLORS.primary;
  const btnBgThemed = theme?.['theme-color-neutral'] ?? btnHex;
  const textColorThemed =
    theme?.['theme-color-tertiary'] ?? buttonColor === 'Light' ? COLORS.black : COLORS.white;

  const getLocalizedLink = {
    url: link?.url as string,
    internalLink: {
      ...link?.internalLink,
    },
  };
  const linkUrl = LocalizationService.getLocalizedLinkPath({
    item: getLocalizedLink,
    pathTranslations,
  });

  return (
    <div className="relative" data-test={[`${id}-container`]}>
      {(fullBleed && (
        <>
          <FullBleedImageTextContainer>
            <FullBleedImageTextWrapper $textAlignment={textAlignment ?? 'Left'}>
              <FullBleedText data-test={[`${id}-full-bleed-text`]}>
                {superText && (
                  <FadeInSection delay="200ms">
                    <SuperText data-test={[`${id}-full-bleed-super-text`]} tag="p" type="lead">
                      {superText}
                    </SuperText>
                  </FadeInSection>
                )}
                {title && (
                  <FadeInSection delay="400ms">
                    <ProductTitle data-test={[`${id}-full-bleed-product-title`]} tag="h2" type="md">
                      {title}
                    </ProductTitle>
                  </FadeInSection>
                )}
                {description && (
                  <FadeInSection delay="600ms">
                    <Description data-test={[`${id}-full-bleed-description`]} tag="p" type="md">
                      {description}
                    </Description>
                  </FadeInSection>
                )}
                {linkUrl && (
                  <FadeInSection delay="800ms">
                    <NextLink
                      href={linkUrl}
                      className="inline-block"
                      target={link?.openInNewTab ? '_blank' : '_self'}
                    >
                      <span className="sr-only">{link?.displayName}</span>
                      <ButtonContainer data-test={[`${id}-full-bleed-button`]}>
                        <ButtonStyled>
                          <LiquidButtonElement
                            text={link?.displayName ?? ''}
                            width={250}
                            ctaColor={btnHex}
                            textColor={buttonColor === 'Light' ? COLORS.black : COLORS.white}
                          />
                        </ButtonStyled>
                      </ButtonContainer>
                    </NextLink>
                  </FadeInSection>
                )}
              </FullBleedText>

              <FullBleedImage data-test={[`${id}-full-bleed-image`]}>
                <FadeInSection delay="200ms">
                  <FullBleedSVG backgroundAsset={backgroundAsset?.asset[0]?.background || ''} />
                </FadeInSection>
              </FullBleedImage>
            </FullBleedImageTextWrapper>
          </FullBleedImageTextContainer>

          <FullBleedImageTextContainerMobile>
            <FullBleedImage data-test={[`${id}-full-bleed-mobile-image`]}>
              <FullBleedMobileSVG
                backgroundAsset={backgroundAsset?.asset[0]?.background || ''}
                mobileBackgroundAsset={mobileBackgroundAsset?.asset[0]?.background || ''}
              />
            </FullBleedImage>
            <FullBleedImageTextWrapper>
              <FullBleedText data-test={[`${id}-full-bleed-mobile-text`]}>
                {superText && (
                  <SuperText data-test={[`${id}-full-bleed-mobile-super-text`]} tag="p" type="lead">
                    {superText}
                  </SuperText>
                )}
                {title && (
                  <ProductTitle
                    data-test={[`${id}-full-bleed-mobile-product-title`]}
                    tag="h3"
                    type="md"
                  >
                    {title}
                  </ProductTitle>
                )}
                {description && (
                  <Description
                    data-test={[`${id}-full-bleed-mobile-description`]}
                    tag="p"
                    type="md"
                  >
                    {description}
                  </Description>
                )}
                {linkUrl && (
                  <NextLink
                    href={linkUrl}
                    className="inline-block"
                    target={link?.openInNewTab ? '_blank' : '_self'}
                  >
                    <span className="sr-only">{link?.displayName}</span>
                    <ButtonContainer data-test={[`${id}-full-bleed-mobile-button`]}>
                      <ButtonMobileStyled>
                        <LiquidButtonElement
                          text={link?.displayName ?? ''}
                          width={150}
                          ctaColor={btnHex}
                          textColor={buttonColor === 'Light' ? COLORS.black : COLORS.white}
                        />
                      </ButtonMobileStyled>
                    </ButtonContainer>
                  </NextLink>
                )}
              </FullBleedText>
            </FullBleedImageTextWrapper>
          </FullBleedImageTextContainerMobile>
        </>
      )) || (
        <>
          {(textAlignment === 'Right' && (
            <>
              <ImageTextWrapper>
                <CurvesBothSides className={`curve-banner-2 ${theme && 'bg-tertiary'}`}>
                  <Grid>
                    <GridRow columns={16} className="md:min-h-[inherit] relative">
                      <GridItem colStart={1} colSpan={8}>
                        <LeftImageContainer data-test={[`${id}-left-image`]}>
                          <FadeInSection delay="200ms">
                            {backgroundAsset?.asset[0]?.background && (
                              <div className="relative w-full h-full">
                                <NextImage
                                  src={backgroundAsset?.asset[0]?.background}
                                  mimeType={String(backgroundAsset?.asset[0]?.mimeType)}
                                  alt="banner 2 image"
                                  className="object-cover"
                                />
                              </div>
                            )}
                          </FadeInSection>
                        </LeftImageContainer>
                      </GridItem>
                      <GridItem colStart={10} colSpan={7}>
                        <VerticalAlignMiddle>
                          <LeftRightTextContainer data-test={[`${id}-left-text`]}>
                            {superText && (
                              <FadeInSection delay="200ms">
                                <SuperText
                                  data-test={[`${id}-left-super-text`]}
                                  tag="p"
                                  type="lead"
                                >
                                  {superText}
                                </SuperText>
                              </FadeInSection>
                            )}
                            {title && (
                              <FadeInSection delay="400ms">
                                <ProductTitle
                                  data-test={[`${id}-left-product-title`]}
                                  tag="h3"
                                  type="md"
                                >
                                  {title}
                                </ProductTitle>
                              </FadeInSection>
                            )}
                            {description && (
                              <FadeInSection delay="600ms">
                                <Description
                                  data-test={[`${id}-left-description`]}
                                  tag="p"
                                  type="md"
                                >
                                  {description}
                                </Description>
                              </FadeInSection>
                            )}
                            {linkUrl && (
                              <FadeInSection delay="800ms">
                                <NextLink
                                  href={linkUrl}
                                  className="inline-block"
                                  target={link?.openInNewTab ? '_blank' : '_self'}
                                >
                                  <span className="sr-only">{link?.displayName}</span>
                                  <ButtonContainer data-test={[`${id}-left-button`]}>
                                    <ButtonStyled>
                                      <LiquidButtonElement
                                        text={link?.displayName ?? ''}
                                        width={250}
                                        ctaColor={btnBgThemed}
                                        textColor={textColorThemed}
                                        hasTheme={!!theme}
                                      />
                                    </ButtonStyled>
                                  </ButtonContainer>
                                </NextLink>
                              </FadeInSection>
                            )}
                          </LeftRightTextContainer>
                        </VerticalAlignMiddle>
                      </GridItem>
                    </GridRow>
                  </Grid>
                </CurvesBothSides>
              </ImageTextWrapper>
            </>
          )) || (
            <ImageTextWrapper>
              <CurvesBothSides className={`curve-banner-2 ${theme && 'bg-tertiary'}`}>
                <Grid>
                  <GridRow columns={16} className="md:min-h-[inherit] relative">
                    <GridItem colStart={1} colSpan={7}>
                      <VerticalAlignMiddle>
                        <LeftRightTextContainer data-test={[`${id}-right-text`]}>
                          {superText && (
                            <FadeInSection delay="200ms">
                              <SuperText data-test={[`${id}-right-super-text`]} tag="p" type="lead">
                                {superText}
                              </SuperText>
                            </FadeInSection>
                          )}
                          {title && (
                            <FadeInSection delay="400ms">
                              <ProductTitle
                                data-test={[`${id}-right-product-title`]}
                                tag="h3"
                                type="md"
                              >
                                {title}
                              </ProductTitle>
                            </FadeInSection>
                          )}
                          {description && (
                            <FadeInSection delay="600ms">
                              <Description
                                data-test={[`${id}-right-description`]}
                                tag="p"
                                type="md"
                              >
                                {description}
                              </Description>
                            </FadeInSection>
                          )}
                          {linkUrl && (
                            <FadeInSection delay="800ms">
                              <NextLink
                                href={linkUrl}
                                className="inline-block"
                                target={link?.openInNewTab ? '_blank' : '_self'}
                              >
                                <span className="sr-only">{link?.displayName}</span>
                                <ButtonContainer data-test={[`${id}-right-button`]}>
                                  <ButtonStyled>
                                    <LiquidButtonElement
                                      text={link?.displayName ?? ''}
                                      width={250}
                                      ctaColor={btnBgThemed}
                                      textColor={textColorThemed}
                                      hasTheme={!!theme}
                                    />
                                  </ButtonStyled>
                                </ButtonContainer>
                              </NextLink>
                            </FadeInSection>
                          )}
                        </LeftRightTextContainer>
                      </VerticalAlignMiddle>
                    </GridItem>
                    <GridItem colStart={9} colSpan={8}>
                      <RightImageContainer data-test={[`${id}-right-image`]}>
                        <FadeInSection delay="200ms">
                          {backgroundAsset?.asset[0]?.background && (
                            <div className="relative w-full h-full">
                              <NextImage
                                src={backgroundAsset?.asset[0]?.background}
                                mimeType={String(backgroundAsset?.asset[0]?.mimeType)}
                                alt="banner 2 image"
                                className="object-cover"
                              />
                            </div>
                          )}
                        </FadeInSection>
                      </RightImageContainer>
                    </GridItem>
                  </GridRow>
                </Grid>
              </CurvesBothSides>
            </ImageTextWrapper>
          )}
          <ImageTextWrapperMobile>
            <LeftRightImageContainerMobile
              data-test={[`${id}-left-mobile-image`]}
              className="curve-banner-2-mobile-top bg-tertiary"
            >
              {(mobileBackgroundAsset?.asset[0]?.background ||
                backgroundAsset?.asset[0]?.background) && (
                <div className="relative w-full h-full min-h-354">
                  <NextImage
                    src={
                      mobileBackgroundAsset?.asset[0]?.background ||
                      backgroundAsset?.asset[0]?.background ||
                      ''
                    }
                    mimeType={String(
                      backgroundAsset?.asset[0]?.mimeType ||
                        mobileBackgroundAsset?.asset[0]?.mimeType,
                    )}
                    alt="banner 2 image"
                    className="object-cover"
                  />
                </div>
              )}
            </LeftRightImageContainerMobile>
            <LeftRightTextContainer
              data-test={[`${id}-left-mobile-text`]}
              className={theme && 'curve-banner-2 bg-tertiary !-mt-8 !pt-16 !pb-10'}
            >
              {superText && (
                <SuperText data-test={[`${id}-left-mobile-super-text`]} tag="p" type="lead">
                  {superText}
                </SuperText>
              )}
              {title && (
                <ProductTitle data-test={[`${id}-left-mobile-product-title`]} tag="h3" type="md">
                  {title}
                </ProductTitle>
              )}
              {description && (
                <Description data-test={[`${id}-left-mobile-description`]} tag="p" type="md">
                  {description}
                </Description>
              )}
              {linkUrl && (
                <NextLink
                  href={linkUrl}
                  className="inline-block"
                  target={link?.openInNewTab ? '_blank' : '_self'}
                >
                  <span className="sr-only">{link?.displayName}</span>
                  <ButtonContainer data-test={[`${id}-left-mobile-button`]}>
                    <ButtonMobileStyled>
                      <LiquidButtonElement
                        text={link?.displayName ?? ''}
                        width={150}
                        ctaColor={btnBgThemed}
                        textColor={textColorThemed}
                        hasTheme={!!theme}
                      />
                    </ButtonMobileStyled>
                  </ButtonContainer>
                </NextLink>
              )}
            </LeftRightTextContainer>
          </ImageTextWrapperMobile>
        </>
      )}
    </div>
  );
};
